<template>
  <v-card>
    <v-card-title>{{ `${$t('menu.configuration')} /  ${$t('landing.evento')} / ${getTitle(event)}` }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="$router.push({ name: 'events-list' })"
          >
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            <span>{{ $t('btn.back') }}</span>
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="error"
            class="mb-4 me-3"
            @click="bloquear()"
          >
            <span>{{ $t('landing.eventSoli.bloquear') }}</span>
            <v-icon>{{ icons.mdiCancel }}</v-icon>
          </v-btn>
        </v-col>
        <v-col
          v-if="permisos.includes('events:create')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          v-if="event.citas_denegadas.length > 0"
          cols="12"
          md="3"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="isDialogDenegadas = true"
          >
            <v-icon>mdi-list-status</v-icon>
            <span>{{ $t('landing.eventSoli.citaDenegadas') }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="match-height"
      >
        <!-- Multiple Column -->
        <v-col cols="12">
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-tabs v-model="pos">
                    <v-tab
                      v-for="(time, indT) in event.times"
                      :key="indT"
                    >
                      {{ $moment(time.date).locale($i18n.locale).format('ddd') }}
                      <br />
                      {{ $moment(time.date).locale($i18n.locale).format('D/MMM') }}
                    </v-tab>
                  </v-tabs>

                  <v-divider></v-divider>

                  <v-tabs-items v-model="pos">
                    <v-tab-item
                      v-for="(time, indTi) in event.times"
                      :key="indTi"
                    >
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                width="5%"
                                style="text-align: center;"
                              >
                              </th>
                              <th
                                class="text-uppercase"
                                width="5%"
                              >
                                {{ $t('landing.eventSoli.time') }}
                              </th>
                              <th
                                style="text-align: center;"
                              >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(iten, index) in time.times"
                              :key="index"
                              :style="iten.busyByGestor && iten.busy ? 'background-color: #fea154;' : ''"
                              :class="iten.busyByGestor && iten.busy ? 'cursor-pointer' : ''"
                              @click="iten.busyByGestor && iten.busy ? bloquearHora(iten, time.date) : ''"
                            >
                              <td style="text-align: center;">
                                <!--<v-tooltip
                                  v-if="permisos.includes('events:edit') && !iten.busy"
                                  top
                                  color="error"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      @click="bloquearHora(iten, time.date)"
                                      v-on="on"
                                    >
                                      <v-icon color="error">
                                        mdi-cancel
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('landing.eventSoli.bloquearDay') }}</span>
                                </v-tooltip>

                                <v-tooltip
                                  v-if="permisos.includes('events:edit') && iten.busy"
                                  top
                                  color="success"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      @click="bloquearHora(iten, time.date)"
                                      v-on="on"
                                    >
                                      <v-icon color="success">
                                        mdi-check
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('landing.eventSoli.desbloquearDay') }}</span>
                                </v-tooltip>-->
                                <template v-if="iten.cita">
                                  <v-menu
                                    v-if="iten.cita.procesada"
                                    bottom
                                    left
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                      </v-btn>
                                    </template>

                                    <v-list>
                                      <v-list-item
                                        v-if="permisos.includes('events:create')"
                                        link
                                        @click="editItem(iten.cita.uiid)"
                                      >
                                        <v-list-item-title>
                                          <v-btn icon>
                                            <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                                          </v-btn>
                                          <span>{{ $t('btn.edit') }}</span>
                                        </v-list-item-title>
                                      </v-list-item>
                                      <v-list-item
                                        v-if="permisos.includes('events:destroy')"
                                        link
                                        @click="deleteItem(iten.cita)"
                                      >
                                        <v-list-item-title>
                                          <v-btn icon>
                                            <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                                          </v-btn>
                                          <span>{{ $t('btn.delete') }}</span>
                                        </v-list-item-title>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </template>
                              </td>
                              <td>
                                {{ iten.time }}
                              </td>
                              <td>
                                <span
                                  v-if="iten.cita"
                                >
                                  <span v-if="iten.cita.confirmada === true">
                                    <span :style="iten.cita.procesada === false ? 'color: red' : ''">

                                      {{ `${iten.cita.name}, ${iten.cita.rol ? iten.cita.rol : ''}` }}
                                      <span v-if="iten.cita.company">
                                        - {{ iten.cita.company }}
                                      </span>
                                      <span v-if="iten.cita.site_web"><a
                                        :href="iten.cita.site_web"
                                        target="_blank"
                                      >{{ iten.cita.site_web }}</a></span>

                                      <v-tooltip
                                        v-if="!iten.cita.procesada"
                                        bottom
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            color="error"
                                            icon
                                            v-bind="attrs"
                                            @click="procesarCita(iten.cita)"
                                            v-on="on"
                                          >
                                            <span>
                                              <v-icon>
                                                mdi-square-edit-outline
                                              </v-icon>
                                            </span>
                                          </v-btn>
                                        </template>
                                        <span>{{ $t('btn.edit') }}</span>
                                      </v-tooltip>

                                      <v-tooltip
                                        v-if="iten.cita.comentarios"
                                        max-width="350"
                                        bottom
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <span
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            <span>
                                              {{ iten.cita.comentarios | truncate(45) }}
                                            </span>
                                          </span>
                                        </template>
                                        <span>{{ iten.cita.comentarios }}</span>
                                      </v-tooltip>
                                    </span>
                                  </span>

                                  <span v-if="iten.cita.confirmada === false">
                                    <span style="color: black">
                                      {{ `${iten.cita.name}, ${iten.cita.rol}` }}
                                      -
                                      {{ iten.cita.company }} <span v-if="iten.cita.site_web"><a
                                        :href="iten.cita.site_web"
                                        target="_blank"
                                      >{{ iten.cita.site_web }}</a></span>
                                    </span>
                                    <v-tooltip
                                      color="primary"
                                      bottom
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                          class="ma-2 cursor-pointer"
                                          outlined
                                          pill
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          {{ $t('landing.event.notConfirm') }}
                                          <v-icon right>
                                            mdi-account-outline
                                          </v-icon>
                                        </v-chip>
                                      </template>
                                      <span>{{ $t('landing.event.notConfirmBody') }}</span>
                                    </v-tooltip>
                                  </span>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="isDialogBloqueo"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('landing.eventSoli.desbloquearDay')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p class="mt-5">
            {{ $t('landing.eventSoli.msgDesbloquearDay', { n: timeEdit.time }) }}
          </p>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogBloqueo = !isDialogBloqueo"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmBusyTimeEvent()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DEFINIR ESTADO DE CITA-->
    <v-dialog
      v-model="isShowCita"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ citaEdit.name }} {{ citaEdit.company }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{ $t('landing.eventSoli.date') }}: {{ $moment(citaEdit.date).locale($i18n.locale).format('ddd, D MMM') }}
              <br />
              {{ $t('landing.eventSoli.time') }}: {{ citaEdit.time }}
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="citaEdit.agendar"
                :items="agendarOps"
                :label="$t('landing.eventSoli.cita')"
                outlined
                dense
                item-text="name"
                item-value="slug"
                hide-details="auto"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isShowCita = !isShowCita"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmAgendar()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BLOQUEAR RANGO HORAS-->
    <v-dialog
      v-if="isDialogBloquear"
      v-model="isDialogBloquear"
      scrollable
      max-width="450px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('landing.eventSoli.bloquearRange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <!--<p>
                {{ $t('landing.eventSoli.msgBloquearRange', { n: `
                ${$moment(event.times[pos].date).locale($i18n.locale).format('ddd')},
                ${$moment(event.times[pos].date).locale($i18n.locale).format('D/MMM')}
                ` }) }}
              </p>-->
              <p>
                {{ $t('landing.eventSoli.msgBloquearRangeDays') }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="timesBloquear"
                :items="datesBloquear"
                :label="$t('lbl.days')"
                outlined
                dense
                hide-details="auto"
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggle"
                  >
                    <v-list-item-action>
                      <v-icon :color="timesBloquear.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('lbl.selectAll') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="fromBloquear"
                :items="fromTimes"
                :label="$t('lbl.from')"
                outlined
                dense
                hide-details="auto"
                @change="changeFromTime()"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="toBloquear"
                :items="toTimes"
                :label="$t('lbl.to')"
                outlined
                dense
                hide-details="auto"
                :disabled="!fromBloquear"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogBloquear = !isDialogBloquear"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            :disabled="
              timesBloquear.length === 0
                || !fromBloquear
            "
            @click="confirmBusyRangeEvent()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--CITAS DENEGADAS-->
    <v-dialog
      v-if="isDialogDenegadas"
      v-model="isDialogDenegadas"
      scrollable
      max-width="550px"
    >
      <v-card max-height="600px">
        <v-card-title>{{ `${$t('landing.eventSoli.citaDenegadas')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              v-for="(deneg, indD) in event.citas_denegadas"
              :key="indD"
              cols="12"
            >
              <v-card>
                <v-card-text>
                  <p>
                    {{ $t('landing.eventSoli.date') }}: {{ deneg.date }}<br />
                    {{ $t('landing.eventSoli.time') }}: {{ deneg.time }}<br />
                    {{ $t('landing.eventSoli.fullname') }}: {{ deneg.name }}<br />
                    Email: {{ deneg.email }}<br />
                    {{ $t('landing.eventSoli.company') }}: {{ deneg.company }}<br />
                    {{ $t('landing.eventSoli.rol') }}: {{ deneg.rol }}<br />
                    <span v-if="deneg.site_web">
                      {{ $t('landing.eventSoli.sitioWeb') }}: {{ deneg.site_web }}<br />
                    </span>
                    <span v-if="deneg.phone">
                      {{ $t('register.noTelefo') }}: {{ deneg.code_country }} {{ deneg.phone }}<br />
                    </span>
                    <v-tooltip
                      v-if="deneg.comentarios"
                      max-width="350"
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>
                            {{ deneg.comentarios | truncate(45) }}
                          </span>
                        </span>
                      </template>
                      <span>{{ deneg.comentarios }}</span>
                    </v-tooltip>
                  </p>
                </v-card-text>
              </v-card>
              <v-divider
                v-if="indD !== event.citas_denegadas.length - 1"
                class="my-1"
              ></v-divider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogDenegadas = !isDialogDenegadas"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('landing.eventSoli.msgDeleteCita', { n: citaEdit.name }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiInformation,
  mdiCancel,
} from '@mdi/js'
import Events from '../utils/Events.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Events,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isDialogBloqueo: false,
      isDialogBloquear: false,
      isDialogDenegadas: false,
      isDialogVisibleDelete: false,
      loading: false,
      timeEdit: {},
      citaEdit: {},
      isShowCita: false,
      event: {
        title: '',
        dateFrom: '',
        citas_denegadas: [],
      },
      agendarOps: [],
      qr: null,
      imgs: [],
      img: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiInformation,
        mdiCancel,
      },
      model: {},
      id_model: -1,
      permisos: localStorage.getItem('permisos_auth'),
      pos: 0,
      fromBloquear: null,
      fromTimes: [],
      toTimes: [],
      toBloquear: null,
      datesBloquear: [],
      timesBloquear: [],
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
      ctasBancarias: state => state.app.ctasBancarias,
      dataBancaria: state => state.app.dataBancaria,
      posCotizador: state => state.app.posCotizador,
    }),
    likesAllFruit() {
      return this.timesBloquear.length === this.datesBloquear.length
    },
    likesSomeFruit() {
      return this.timesBloquear.length > 0 && !this.likesAllFruit
    },
    icon() {
      if (this.likesAllFruit) return 'mdi-close-box'
      if (this.likesSomeFruit) return 'mdi-minus-box'

      return 'mdi-checkbox-blank-outline'
    },
  },
  created() {
    // this.pos = this.posCotizador
    this.pos = 0
    this.getEvent()
    this.getQR()
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event1.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event2.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event3.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event4.svg'))

    this.agendarOps.push({
      name: 'Confirmar',
      slug: 'confirmar',
    })
    this.agendarOps.push({
      name: 'Denegar',
      slug: 'denegar',
    })
  },
  methods: {
    ...mapMutations([
      'addEmailAdminReserve',
      'updateEmailAdminReserve',
      'addCtasBancarias',
      'updateCtasBancarias',
      'setPosCotizador',
      'updateDataBancaria',
    ]),
    getTitle(item) {
      if (this.$i18n.locale === 'es') {
        return item.title
      }

      return item[`title_${this.$i18n.locale}`]
    },
    getDescripcion(item) {
      if (this.$i18n.locale === 'es') {
        return item.description
      }

      return item[`description_${this.$i18n.locale}`]
    },
    getEvent() {
      this.axios
        .post('events/show-event', { id: localStorage.getItem('event-id') }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            this.$router.push({ name: 'error-404' })
          } else {
            this.event = res.data.data.item

            this.event.times.forEach(element => {
              this.datesBloquear.push(element.date)
            })

            this.isLoading = false
          }
        })
        .finally(() => {
          this.shuffleDeck()
        })
    },
    getQR() {
      this.axios
        .post('catalogs/qr-event', { slug: this.$route.params.slug })
        .then(res => {
          this.qr = res.data
        })
    },
    shuffleDeck() {
      const numbers = [...this.imgs]
      let first
      let second
      let temp
      const count = numbers.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        first = Math.floor(Math.random() * count)
        second = Math.floor(Math.random() * count)
        temp = numbers[first]
        numbers[first] = numbers[second]
        numbers[second] = temp
      }
      this.imgs = numbers
      if (this.event.logo) {
        this.img = this.urlS3 + this.event.logo
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.img = this.imgs[0]
      }
    },

    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.timesBloquear = []
        } else {
          this.timesBloquear = this.datesBloquear.slice()
        }
      })
    },
    bloquearHora(time, date) {
      this.timeEdit = time
      this.timeEdit.date = date
      this.isDialogBloqueo = true
    },
    confirmBusyTimeEvent() {
      this.loading = true
      this.timeEdit.busy = !this.timeEdit.busy
      const json = {
        slug: this.$route.params.slug,
        ...this.timeEdit,
      }

      this.axios
        .post('events/update-busy-time-event', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(per => {
          if (per.data.success) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          } else {
            this.$toast.error(per.data.message)
          }
        })
        .finally(() => {
          this.loading = false
          this.isDialogBloqueo = false
          this.getEvent()
        })
    },
    procesarCita(cita) {
      this.citaEdit = {
        agendar: '',
        name: cita.name,
        company: cita.company,
        rol: cita.rol,
        uiid: cita.uiid,
        date: cita.date,
        time: cita.time,
        event_id: this.event.id,
      }
      this.isShowCita = true
    },
    confirmAgendar() {
      if (this.citaEdit.agendar) {
        this.loading = true
        this.axios
          .post('events/update-agendar-event', this.citaEdit, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(per => {
            if (per.data.success) {
              this.$toast.success(this.$t('msg.infoSuccess'))
            }
          })
          .finally(() => {
            this.loading = false
            this.isShowCita = false
            this.getEvent()
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    bloquear() {
      this.event.times[this.pos].times.forEach(element => {
        this.fromTimes.push(element.time)
      })
      this.timesBloquear = []
      this.timesBloquear.push(this.event.times[this.pos].date)
      this.isDialogBloquear = true
    },
    changeFromTime() {
      this.toBloquear = null
      this.toTimes = []
      this.event.times[this.pos].times.forEach(element => {
        if (element.time > this.fromBloquear) {
          this.toTimes.push(element.time)
        }
      })
    },
    confirmBusyRangeEvent() {
      this.loading = true
      const json = {
        slug: this.$route.params.slug,
        dates: this.timesBloquear,
        fromTime: this.fromBloquear,
        toTime: this.toBloquear,
      }

      this.axios
        .post('events/busy-times-event', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(per => {
          if (per.data.success) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          } else {
            this.$toast.error(per.data.message)
          }
        })
        .finally(() => {
          this.loading = false
          this.isDialogBloquear = false
          this.fromBloquear = null
          this.toBloquear = null
          this.getEvent()
        })
    },

    newItem() {
      localStorage.removeItem('event-cita-id')
      this.$router.push({
        name: 'event-cita-update',
        params: {
          slug: this.$route.params.slug,
        },
      })
    },
    editItem(uiid) {
      localStorage.setItem('event-cita-id', uiid)
      this.$router.push({
        name: 'event-cita-update',
        params: {
          slug: this.$route.params.slug,
        },
      })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.citaEdit = iten
    },
    async confirmDelete() {
      this.loading = true
      const json = {
        slug: this.$route.params.slug,
        ...this.citaEdit,
      }
      await this.axios
        .post('events/delete-cita-gestor', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(per => {
          if (per.data.success) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => {
          this.loading = false
          this.isDialogVisibleDelete = false
          this.getEvent()
        })
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
